import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useKrispToggle } from '../../../hooks/useKrispToggle/useKrispToggle';
import SmallCheckIcon from '../../../icons/SmallCheckIcon';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import Timmer from '../../Timmer';
import moment from 'moment';
import ToggleAudioButtonjoinScreen from '../../Buttons/ToggleAudioButton/ToggleAudioButtonJoinScreen';
import ToggleVideoButtonScreenShare from '../../Buttons/ToggleVideoButton/ToggleVideoButtonScreenShare';
import { FormControl } from '@mui/material';
import { Switch } from 'antd';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useHeight from '../../../hooks/useHeight/useHeight';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1.5em',
    marginLeft: 20,
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '20%',
    border: '2px solid #aaa',
    margin: '1em 0',
    marginLeft: 30,
  },
  localPreviewContainer: {
    paddingRight: '2em',
    marginBottom: '2em',
    // [theme.breakpoints.down('sm')]: {
    //   padding: '0 2.5em',
    // },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
    // marginLeft: 10,
    marginTop: -10,
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  toolTipContainer: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      marginLeft: '0.3em',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;

  setStep: (step: Steps) => void;
}

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, isKrispEnabled, isKrispInstalled } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [roomId, setRoomId] = useState();
  const [currentTime, setCurrentTime] = useState<any>();
  const [Start_Time, setStart_Time] = useState<any>();
  const [End_Time, setEnd_Time] = useState<any>();
  const [ButtonState, SetButtonState] = useState(true);
  const [countDownTime, setCountDownTime] = React.useState(0);

  const timerRef = useRef(countDownTime);
  const [reverseCountDownTime, setReverseCountDownTime] = React.useState(0);
  const reverseTimerRef = useRef(reverseCountDownTime);

  useEffect(() => {
     const timerId = setInterval(() => {
      timerRef.current -= 1;
      if (timerRef.current < 0) {
        timerRef.current = 0;
        clearInterval(timerId);
      } else {
        if (timerRef.current < 61) {
          SetButtonState(false);
        }
        setCountDownTime(timerRef.current);
        }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [countDownTime]);

  useEffect(() => {
    const reverseTimerId = setInterval(() => {
      reverseTimerRef.current -= 1;
      if (reverseTimerRef.current < 0) {
        reverseTimerRef.current = 0;
        clearInterval(reverseTimerId);
      } else {
        setReverseCountDownTime(reverseTimerRef.current);
      }
    }, 1000);
    return () => {
      clearInterval(reverseTimerId);
    };
  }, [reverseCountDownTime]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const usertype = queryParameters.get('usertype');
    const user_type = queryParameters.get('user_type');
    const userid = queryParameters.get('userid');
    const id = queryParameters.get('id');
    const param_1 = id;
    const param_2 = usertype;
    const param_3 = userid;
    const param_4 = user_type;

    fetch(
      'https://kaprat.com/dev/jobot/api/call?id=' +
      param_1 +
      '&usertype=' +
      param_2 +
      '&userid=' +
      param_3 +
      '&user_type=' +
      param_4 +
      '',
      {
        method: 'GET',
        headers: {
          authorizationtoken:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTIiLCJ1c2VyX3R5cGUiOiJ1c2VyIiwiZW1haWwiOiJuYXJlbmRyYS53ZWJAYXJjY3VzLmluIiwiY29udGFjdCI6Ijk4Nzk0NDc0ODgiLCJkZXZpY2VfdHlwZSI6IiIsImRldmljZV90b2tlbiI6IiIsImNyZWF0ZWRfYXQiOiIxMyBNYXkgMjAyMiAwMjo1NyBwbSIsImxvZ2dlZGluX2xhbmciOiJlbiIsInRpbWUiOjE2NzEyNTQ2MzB9.lW8Piw_4OTt_rbqmPo7sEtScTwGhcvtdhHInjEKOHg8',
        },
      }
    ).then(response => {
      console.log('main response ', response);
      if (response.status === 200) {
        response.json().then(resp => {
          console.log('result is..', resp);
          if (resp && resp.data) {
            setRoomId(resp.data.room_id);
            setEnd_Time(moment(resp.data.end_time, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            let tempEnd = resp.data.end_time;

            // setStart_Time(moment('30-12-2022 18:41:00', 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            setStart_Time(moment(resp.data.start_time, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            // let tempStart = '30-12-2022 18:41:00';
            let tempStart = resp.data.start_time;
            timeConditionCheck(tempStart, tempEnd);
          }
        });
      }
    });
  };

  const timeConditionCheck = (startTime: any, endTime: any) => {
    var current_time = moment()
      .utcOffset('+05:30')
      .format('DD-MM-YYYY HH:mm:ss');

    setCurrentTime(current_time);

    var tempCurrentTimeDifference = moment(current_time, 'DD-MM-YYYY HH:mm:ss').diff(
      moment(startTime, 'DD-MM-YYYY HH:mm:ss')
    );
    let countDownTimeInSecond = Number(tempCurrentTimeDifference) / 1000;

    if (
      moment(startTime, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime() >
      moment(current_time, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime()
    ) {
      console.log('meeting will start in');
      SetButtonState(true);

      setCountDownTime(prevState => {
        prevState = Number(countDownTimeInSecond.toString().replace('-', ''));
        timerRef.current = Number(countDownTimeInSecond.toString().replace('-', ''));
        return prevState;
      });
    } else if (
      moment(current_time, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime() <
      moment(endTime, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime() &&
      moment(current_time, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime() >
      moment(startTime, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime()
    ) {
      SetButtonState(false);
      console.log('meeting already started');



    } else if (
      moment(current_time, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime() >
      moment(endTime, 'DD-MM-YYYY HH:mm:ss')
        .toDate()
        .getTime()
    ) {
      console.log('meeting already ended.');
      SetButtonState(false);
    } else {
      console.log('Please check logic.');
    }
  };



  function pad(num: any) {
    return ('0' + num).slice(-2);
  }
  function hhmmss(secs: any) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  }

  const handleJoin = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const userType = queryParameters.get('usertype');
    const user_type = queryParameters.get('user_type');
    const userId = queryParameters.get('userid');
    const id = queryParameters.get('id');

    const param_1 = id;
    const param_2 = userType;
    const param_3 = userId;
    const param_4 = user_type;
    fetch(
      'https://kaprat.com/dev/jobot/api/joincall?id=' +
      param_1 +
      '&usertype=' +
      param_2 +
      '&userid=' +
      param_3 +
      '&user_type=' +
      param_4 +
      '',
      {
        method: 'GET',

        headers: {
          authorizationtoken:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTIiLCJ1c2VyX3R5cGUiOiJ1c2VyIiwiZW1haWwiOiJuYXJlbmRyYS53ZWJAYXJjY3VzLmluIiwiY29udGFjdCI6Ijk4Nzk0NDc0ODgiLCJkZXZpY2VfdHlwZSI6IiIsImRldmljZV90b2tlbiI6IiIsImNyZWF0ZWRfYXQiOiIxMyBNYXkgMjAyMiAwMjo1NyBwbSIsImxvZ2dlZGluX2xhbmciOiJlbiIsInRpbWUiOjE2NzEyNTQ2MzB9.lW8Piw_4OTt_rbqmPo7sEtScTwGhcvtdhHInjEKOHg8',
        },
      }
    ).then(resp => {
      resp.json().then(resp => {
        console.log('result is..', resp);
        console.log('name is', resp.data.user_name);
        // if (resp.status === 1 && user_type == 'user') {
        //   if (resp.applicant_joined == 'Y') {
        //     // 'bothvideocallscreen' //success both user joined
        //   } else {
        //     //.....
        //   }
        //   // console.log("true....");
        // }
        // if (resp.status === 1 && user_type == 'applicant') {
        //   if (resp.user_joined == 'Y') {
        //     // 'bothvideocallscreen' //success both user joined
        //   } else {
        //     //...
        //   }
        //   getToken(resp.data.user_name, resp.data.room_id).then(({ token }) => {
        //     console.log("token on handle join", token);

        //     videoConnect(token);
        //     // videoConnect("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2JmOGIxYjczNGQyZjQzMTExZjc2Nzc5NTUyZjMzMTMxLTE2NzI3NjUyMTgiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJOYXJlbmRyYSBZYWRhdiIsInZpZGVvIjp7InJvb20iOiJSb29tXzEifSwiY2hhdCI6e319LCJpYXQiOjE2NzI3NjUyMTgsImV4cCI6MTY3Mjc3OTYxOCwiaXNzIjoiU0tiZjhiMWI3MzRkMmY0MzExMWY3Njc3OTU1MmYzMzEzMSIsInN1YiI6IkFDZTQ5NTc3MTNiYWM3Mzg4MGQ4ODQzYjIyMTdlODMxN2YifQ.8MoMqfsWmykEhquH4bGXz8SQNnLe29PTJjelLyqJAgo");
        //     process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
        //   });
        // } else {
          getToken(resp.data.user_name, resp.data.room_id).then(({ token }) => {
            console.log("token on handle join else", token);
            videoConnect(token);
            // videoConnect("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2JmOGIxYjczNGQyZjQzMTExZjc2Nzc5NTUyZjMzMTMxLTE2NzI3NjUyMTgiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJOYXJlbmRyYSBZYWRhdiIsInZpZGVvIjp7InJvb20iOiJSb29tXzEifSwiY2hhdCI6e319LCJpYXQiOjE2NzI3NjUyMTgsImV4cCI6MTY3Mjc3OTYxOCwiaXNzIjoiU0tiZjhiMWI3MzRkMmY0MzExMWY3Njc3OTU1MmYzMzEzMSIsInN1YiI6IkFDZTQ5NTc3MTNiYWM3Mzg4MGQ4ODQzYjIyMTdlODMxN2YifQ.8MoMqfsWmykEhquH4bGXz8SQNnLe29PTJjelLyqJAgo");

            process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
          });
        // }
      });
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }
  return (
    <>
      {/* <Typography variant="h5" className={classes.gutterBottom}>
        <Timmer />
      </Typography> */}
      <Typography
        variant="h5"
        className={classes.gutterBottom}
        style={{ fontWeight: 800, fontFamily: 'Manrope', marginTop: -40 }}
      >
        {(() => {
          if (
            moment(Start_Time, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime() >
            moment(currentTime, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime()
          ) {
            return (
              <h2
                style={{
                  color: 'black',
                  marginTop: '5px',
                  height: '25px',
                  fontSize: '25px',
                }}
              >
                Meeting will start in : {hhmmss(countDownTime)}
              </h2>
            );
          } else if (
            moment(currentTime, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime() <
            moment(End_Time, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime() &&
            moment(currentTime, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime() >
            moment(Start_Time, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime()
          ) {
            return (
              <h2
                style={{
                  color: 'black',
                  marginTop: '5px',
                  height: '25px',
                  fontSize: '25px',
                }}
              >
                Meeting is in progress please join
              </h2>
            );
          } else if (
            moment(currentTime, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime() >
            moment(End_Time, 'DD-MM-YYYY HH:mm:ss')
              .toDate()
              .getTime()
          ) {
            return (
              <h2
                style={{
                  color: 'red',
                  marginTop: '5px',
                  height: '25px',
                  marginLeft: '30px',
                  fontSize: '25px',
                }}
              >
                The meeting has finished!
              </h2>
            );
          }
        })()}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={10} sm={12} xs={12} style={{ marginTop: -15, backgroundColor: 'white' }}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </Hidden>
          </div>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: '1em', marginLeft: -40, marginTop: -20 }}
          >
            <div style={{ display: 'flex' }}>
              <ToggleAudioButtonjoinScreen className={classes.deviceButton} disabled={disableButtons} />
              {/* <Switch onChange={onChange} style={{ marginLeft: -20 }} /> */}

              <ToggleVideoButtonScreenShare className={classes.deviceButton} disabled={disableButtons} />
              {/* <Switch defaultChecked onChange={onChange} style={{ marginLeft: -20 }} /> */}
            </div>
          </Grid>

          {/* <Divider /> */}
        </Grid>

        {/* <Hidden smDown>
              <Grid item md={7} sm={12} xs={12}>
                <SettingsMenu mobileButtonClass={classes.mobileButton} />
              </Grid>
            </Hidden> */}

        <div className={classes.joinButtons}>
          <Button
            variant="outlined"
            color="primary"
            // onClick={() => setStep(Steps.roomNameStep)}
            style={{
              height: 50,
              width: 100,
              border: 'none',
              fontWeight: 600,
              marginTop: 20,
              color: '#00A7E8',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-cy-join-now
            onClick={handleJoin}
            disabled={ButtonState}
            style={{
              margin: 20,
              borderRadius: 10,
              height: 50,
              width: 100,
              backgroundColor: '#00A7E8',
            }}
          >
            Join Now
          </Button>
        </div>
      </Grid>
    </>
  );
}
