import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import DeviceSelectionScreen from '../DeviceSelectionScreen/DeviceSelectionScreen';
import rectangleleft from '../../../images/RectangleLeft.png';
import rectangleright from '../../../images/RectangleRight.png';
const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    fontStyle: 'normal',
    // fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: 24,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor : '#00A7E8',
    height : 42,
    borderRadius : 10,
    bottom : "-150px"
    
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const buttonState = false;
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom} style={{fontWeight : 800 , fontFamily : 'Manrope'}}>
        Meeting will start in : 7:00 Min
      </Typography>
      {/* <div>
        <img src={rectangleleft} style={{ height: 150, width: 120 }} />
        <img src={rectangleright} style={{ height: 150, width: 120 ,marginLeft : 20}} />
      </div> */}
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}></div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={buttonState}
            className={classes.continueButton}
          >
            Continue
          </Button> 
        </Grid>
      </form>
    </>
  );
}
