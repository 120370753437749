import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { Switch } from 'antd';

export default function ToggleVideoButtonScreenShare(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now(); 
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const onChange = (setLoading: boolean) => {
    console.log(`${setLoading}`);
  };
  return (
    <div>
      <Button
        className={props.className}
        disabled={true}
        startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        style={{ border: 'none' }}
      >
        {/* {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'} */}
      </Button>
      <Switch defaultChecked onChange={onChange} onClick={toggleVideo} style={{ marginLeft: -20 }} />
    </div>
  );
}
