import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import e from 'express';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const [Endmassege, SetEndmassege] = useState();
  const { room } = useVideoContext();
  const [roomId, setRoomId] = useState();
  const [Start_Time, setStart_Time] = useState<any>();
  const [End_Time, setEnd_Time] = useState<any>();

  const timerRef = useRef();
  const [reverseCountDownTime, setReverseCountDownTime] = React.useState(0);
  const reverseTimerRef = useRef(reverseCountDownTime);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const reverseTimerId = setInterval(() => {
      reverseTimerRef.current -= 1;
      if (reverseTimerRef.current < 0) {
        reverseTimerRef.current = 0;
        clearInterval(reverseTimerId);
      } else {
        setReverseCountDownTime(reverseTimerRef.current);
      }
    }, 1000);
    return () => {
      clearInterval(reverseTimerId);
    };
  }, [reverseCountDownTime]);

  const getData = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const usertype = queryParameters.get('usertype');
    const user_type = queryParameters.get('user_type');
    const userid = queryParameters.get('userid');
    const id = queryParameters.get('id');
    const param_1 = id;
    const param_2 = usertype;
    const param_3 = userid;
    const param_4 = user_type;

    fetch(
      'https://kaprat.com/dev/jobot/api/call?id=' +
      param_1 +
      '&usertype=' +
      param_2 +
      '&userid=' +
      param_3 +
      '&user_type=' +
      param_4 +
      '',
      {
        method: 'GET',
        headers: {
          authorizationtoken:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTIiLCJ1c2VyX3R5cGUiOiJ1c2VyIiwiZW1haWwiOiJuYXJlbmRyYS53ZWJAYXJjY3VzLmluIiwiY29udGFjdCI6Ijk4Nzk0NDc0ODgiLCJkZXZpY2VfdHlwZSI6IiIsImRldmljZV90b2tlbiI6IiIsImNyZWF0ZWRfYXQiOiIxMyBNYXkgMjAyMiAwMjo1NyBwbSIsImxvZ2dlZGluX2xhbmciOiJlbiIsInRpbWUiOjE2NzEyNTQ2MzB9.lW8Piw_4OTt_rbqmPo7sEtScTwGhcvtdhHInjEKOHg8',
        },
      }
    ).then(response => {
      console.log('main response ', response);
      if (response.status === 200) {
        response.json().then(resp => {
          if (resp && resp.data) {
            setRoomId(resp.data.room_id);

            setEnd_Time(moment(resp.data.end_time, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            let tempEnd = resp.data.end_time;
            // setEnd_Time(moment('30-12-2022 21:50:00', 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            // let tempEnd = '30-12-2022 21:50:00';

            // setStart_Time(moment('30-12-2022 20:55:00', 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            setStart_Time(moment(resp.data.start_time, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
            // let tempStart = '30-12-2022 20:55:00';
            let tempStart = resp.data.start_time;

            var current_time = moment()
              .utcOffset('+05:30')
              .format('DD-MM-YYYY HH:mm:ss');

            var tempDifference = moment(tempEnd, 'DD-MM-YYYY HH:mm:ss').diff(
              moment(current_time, 'DD-MM-YYYY HH:mm:ss')
            );

            let diffrenceInSecond = Number(tempDifference) / 1000;
            // timeConditionCheck(tempStart, tempEnd);

            setReverseCountDownTime(prevState => {
              prevState = Number(diffrenceInSecond.toString().replace('-', ''));
              reverseTimerRef.current = Number(diffrenceInSecond.toString().replace('-', ''));
              return prevState;
            });
          }
        });
      }
    });
  };

  function pad(num: any) {
    return ('0' + num).slice(-2);
  }
  function hhmmss(secs: any) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  }

  const handleClose = () => {
    setOpen(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const usertype = queryParameters.get('usertype');
    const user_type = queryParameters.get('user_type');
    const userid = queryParameters.get('userid');
    const id = queryParameters.get('id');

    const userId = userid;
    const userType = usertype;
    const Id = id;
    const user_Type = user_type;
    const reason = Endmassege;

    fetch(
      'https://kaprat.com/dev/jobot/api/endcall?id=' +
      Id +
      '&usertype=' +
      userType +
      '&userid=' +
      userId +
      '&user_type=' +
      user_Type +
      '&reason=' +
      reason +
      '',
      {
        method: 'GET',

        headers: {
          authorizationtoken:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTIiLCJ1c2VyX3R5cGUiOiJ1c2VyIiwiZW1haWwiOiJuYXJlbmRyYS53ZWJAYXJjY3VzLmluIiwiY29udGFjdCI6Ijk4Nzk0NDc0ODgiLCJkZXZpY2VfdHlwZSI6IiIsImRldmljZV90b2tlbiI6IiIsImNyZWF0ZWRfYXQiOiIxMyBNYXkgMjAyMiAwMjo1NyBwbSIsImxvZ2dlZGluX2xhbmciOiJlbiIsInRpbWUiOjE2NzEyNTQ2MzB9.lW8Piw_4OTt_rbqmPo7sEtScTwGhcvtdhHInjEKOHg8',
        },
      }
    ).then(resp => {
      resp.json().then(result => {
        console.log('result is..', result);
        if (result.status === 1 && userType == 'user') {
          if (result.applicant_joined == 'Y') {
            // 'bothvideocallscreen' //success both user joined
          } else {
            // navigate("endcall"); //waiting screen
          }
          room!.disconnect();
          console.log('true....');
        }
        if (result.status === 1 && userType == 'applicant') {
          if (result.user_joined == 'Y') {
            // 'bothvideocallscreen' //success both user joined
          } else {
            // navigate("meeting"); //waiting screen
          }
          console.log('applicant true....');
          room!.disconnect();
        } else {
          // alert(result.message);
        }
      });
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlevalue = (event: any) => {
    SetEndmassege(event.target.value);

    if (event.target.value.length >= 2) {
      setdisable(false);
    }
  };

  const [disable, setdisable] = useState(true);
  return (
    <div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Please enter reason to end call'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <input
                type="text"
                placeholder="Type a reason"
                onChange={handlevalue}
                style={{ height: 30, width: 300 }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" data-cy-join-now onClick={handleClose} disabled={false}>
              submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: 1400, marginTop: -60 }}>
        <div>
          <h1 style={{ color: 'gray', marginLeft: -70, marginTop: 25, fontSize: 20 }}>{hhmmss(reverseCountDownTime)}</h1>
        </div>

        <div>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.button, props.className)}
            data-cy-join-now
            onClick={handleClickOpen}
            style={{
              margin: 20,
              borderRadius: 10,
              height: 45,
              width: 100,
              backgroundColor: '#00A7E8',
            }}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </div>
  );
}
