import React from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Switch } from 'antd';

export default function ToggleAudioButtonjoinScreen(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const onChange = (setLoading: boolean) => {
    console.log(`${setLoading}`);
  };
  return (
    <div style={{display : 'block'}}>
    <Button 
      className={props.className}
      // onClick={toggleAudioEnabled}
      disabled={true}
      startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      data-cy-audio-toggle
      style={{border : 'none' , height : '40px'}}
    >
      {/* {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'} */}
    </Button>
    <Switch defaultChecked onChange={onChange} onClick={toggleAudioEnabled} style={{ marginLeft: -20 }} />
    </div>
  );
}
   